import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ClientService } from '@app/core/client.service';
import { MatSnackBar } from '@angular/material';

import { ChartOptions, ChartType, ChartDataSets, PluginServiceRegistrationOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import moment from 'moment';
import { TypeOfDate } from '../enum/feedback';
moment.locale('de');

import { Constants, AuthenticationService } from '@app/core';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-statistics-details',
  templateUrl: './statistics-details.component.html',
  styleUrls: ['./statistics-details.component.scss']
})
export class StatisticsDetailsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  public feedbacks: any[] = [];
  public thisMonth: number = 0;
  public lastMonth: number = 0;

  public showChart: boolean = false;
  public loading: boolean = true;
  public showChartDoughnut: boolean = false;
  public showMonths: number = 1200;
  public showErrorMessage: boolean = false;
  public tokenValid: boolean = false;
  public tokenExpiryTimeout: any;

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 5
          }
        }
      ]
    }
  };
  doughnutChatOption: ChartOptions = {
    responsive: true
  };
  doughnutChatOption1: ChartOptions = {
    responsive: true
  };
  doughnutChatOption2: ChartOptions = {
    responsive: true
  };
  doughnutChatOption3: ChartOptions = {
    responsive: true
  };
  doughnutChatOption4: ChartOptions = {
    responsive: true
  };
  doughnutChatOption5: ChartOptions = {
    responsive: true
  };
  barChartLabels: Label[] = [];
  barChartLabels1: Label[] = [];
  barChartLabels2: Label[] = [];
  barChartLabels3: Label[] = [];
  barChartLabels4: Label[] = [];
  barChartLabels5: Label[] = [];

  doughnutChartLabels: string[];
  doughnutChartLabels1: Label[] = [];
  doughnutChartLabels2: Label[] = [];
  doughnutChartLabels3: Label[] = [];
  doughnutChartLabels4: Label[] = [];
  doughnutChartLabels5: Label[] = [];

  barChartType: ChartType = 'bar';
  doughnutChartType: ChartType = 'doughnut';
  barChartLegend = true;
  doughnutChartPlugins: PluginServiceRegistrationOptions = {};

  barChartData: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData1: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData2: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData3: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData4: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData5: ChartDataSets[] = [{ data: [], label: '' }];

  doughnutChartData: ChartDataSets[] = [{ data: [], label: '' }];
  doughnutChartData1: ChartDataSets[] = [{ data: [], label: '' }];
  doughnutChartData2: ChartDataSets[] = [{ data: [], label: '' }];
  doughnutChartData3: ChartDataSets[] = [{ data: [], label: '' }];
  doughnutChartData4: ChartDataSets[] = [{ data: [], label: '' }];
  doughnutChartData5: ChartDataSets[] = [{ data: [], label: '' }];

  items: { value: number; count: number; percentage: number }[] = [];
  items1: { value: number; count: number; percentage: number }[] = [];
  items2: { value: number; count: number; percentage: number }[] = [];
  items3: { value: number; count: number; percentage: number }[] = [];
  items4: { value: number; count: number; percentage: number }[] = [];
  items5: { value: number; count: number; percentage: number }[] = [];

  barChartColors: Color[] = [
    {
      borderColor: '#a4efff',
      backgroundColor: '#a4efff'
    }
  ];

  barChartColors1: Color[] = [
    {
      borderColor: '#29d3ff',
      backgroundColor: '#29d3ff'
    }
  ];
  barChartColors2: Color[] = [
    {
      borderColor: '#17d8a2',
      backgroundColor: '#17d8a2'
    }
  ];
  barChartColors3: Color[] = [
    {
      borderColor: '#fedd56',
      backgroundColor: '#fedd56'
    }
  ];
  barChartColors4: Color[] = [
    {
      borderColor: '#f74d7b',
      backgroundColor: '#f74d7b'
    }
  ];
  barChartColors5: Color[] = [
    {
      borderColor: '#f9be51',
      backgroundColor: '#f9be51'
    }
  ];
  barChartColorsForLines: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    }
  ];
  // Doughnut Chart colors starts
  doughnutChartColors: Color[];
  doughnutChartColors1: Color[];
  doughnutChartColors2: Color[];
  doughnutChartColors3: Color[];
  doughnutChartColors4: Color[];
  doughnutChartColors5: Color[];

  mapValueOfStars: [number, number][] = [];
  mapValueOfStars1: [number, number][] = [];
  mapValueOfStars2: [number, number][] = [];
  mapValueOfStars3: [number, number][] = [];
  mapValueOfStars4: [number, number][] = [];
  mapValueOfStars5: [number, number][] = [];

  // Doughnut chart color Ends

  stars: any[] = [];
  dates: any = {
    from: '',
    to: ''
  };
  filterBy: any = '';
  buttonStyles: any = {};
  buttonStyles1: any = {};
  buttonStyles2: any = {};
  buttonStyles3: any = {};
  selectDate: string = TypeOfDate.MONTHLY;
  dailyAverages: any[];
  googleBusinessLocations: any[];
  location: any = {};
  showReviewData: any = {
    internalReviews: true,
    googleReviews: false
  };
  dailyAveragesGoogle: any[] = [];
  showDetailedReviews: boolean = true;
  constructor(
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.checkTokenValidityAndSetTimer();
    this.googleBusinessLocations = [];
    this.route.queryParams.subscribe(params => {
      if (params['google'] === 'true') {
        this.showReviewData.googleReviews = true;
        this.getAllBusinessLocations();
      }
    });
    this.getFeedbacks(true);
    this.buttonStyles2 = {
      'background-color': 'rgb(24 151 139)',
      color: 'white',
      'border-color': 'rgb(24 151 139)'
    };
  }

  selectLocation(event: any) {
    this.location = this.googleBusinessLocations.find(item => item.id == event);
  }

  getFeedbacks(initialStart: boolean = false) {
    this.loading = true;
    if (initialStart) {
      this.dates.to = moment().format('YYYY-MM-DD');
      this.dates.from = moment()
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
    }
    this.lastMonth = 0;
    this.thisMonth = 0;
    this.stars = [
      {
        label: 'Gesamteindruck',
        value: 0,
        ratings: []
      },
      {
        label: 'Service',
        value: 0,
        ratings: []
      },
      {
        label: 'Sauberkeit',
        value: 0,
        ratings: []
      },
      {
        label: 'Essen',
        value: 0,
        ratings: []
      },
      {
        label: 'Ambiente',
        value: 0,
        ratings: []
      },
      {
        label: 'Preis/Leistung',
        value: 0,
        ratings: []
      }
    ];

    const googleAccessToken = localStorage.getItem('googleAccessToken');
    const accountId = this.location && this.location.accountId ? this.location.accountId : null;
    const locationId = this.location && this.location.id ? this.location.id : null;

    this.clientService
      .getFeedbacks(this.dates.from, this.dates.to, this.showReviewData, googleAccessToken, accountId, locationId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.feedbacks = res.reverse() || [];
        this.feedbacks.forEach((row: any) => {
          this.loading = false;
          if (row.generalRating) {
            this.stars[0].ratings.push(row.generalRating);
          }
          if (row.serviceRating) {
            this.stars[1].ratings.push(row.serviceRating);
          }
          if (row.sauberkeitRating) {
            this.stars[2].ratings.push(row.sauberkeitRating);
          }
          if (row.essenRating) {
            this.stars[3].ratings.push(row.essenRating);
          }
          if (row.ambienteRating) {
            this.stars[4].ratings.push(row.ambienteRating);
          }
          if (row.preisLeistungRating) {
            this.stars[5].ratings.push(row.preisLeistungRating);
          }

          if (
            moment(row.postedAt).format('YMM') >=
            moment()
              .subtract(1, 'months')
              .format('YMM')
          ) {
            this.lastMonth++;
          }
          if (moment(row.postedAt).format('YMM') >= moment().format('YMM')) {
            this.thisMonth++;
          }
        });
        this.setRatings();
        this.getDailyDetailsInTable();
        this.getDailyGeneralReviewsInTable();
      });
  }

  setDates(dateType: string) {
    this.selectDate = dateType;
    if (dateType === TypeOfDate.DAILY) {
      this.buttonStyles1 = {};
      this.buttonStyles2 = {};
      this.buttonStyles3 = {};
      this.buttonStyles = {
        'background-color': 'rgb(24 151 139)',
        color: 'white',
        'border-color': 'rgb(24 151 139)'
      };
    } else if (dateType === TypeOfDate.WEEKLY) {
      this.buttonStyles = {};
      this.buttonStyles2 = {};
      this.buttonStyles3 = {};
      this.buttonStyles1 = {
        'background-color': 'rgb(24 151 139)',
        color: 'white',
        'border-color': 'rgb(24 151 139)'
      };
    } else if (dateType === TypeOfDate.MONTHLY) {
      this.buttonStyles = {};
      this.buttonStyles1 = {};
      this.buttonStyles3 = {};
      this.buttonStyles2 = {
        'background-color': 'rgb(24 151 139)',
        color: 'white',
        'border-color': 'rgb(24 151 139)'
      };
    } else if (dateType == TypeOfDate.LASTSEVEN) {
      this.buttonStyles = {};
      this.buttonStyles1 = {};
      this.buttonStyles2 = {};
      this.buttonStyles3 = {
        'background-color': 'rgb(24 151 139)',
        color: 'white',
        'border-color': 'rgb(24 151 139)'
      };
    }
    this.setRatings();
  }

  getWeekNumber(dateString: any) {
    var d: any = new Date(Date.parse(dateString));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

    return weekNo + '-' + d.getUTCFullYear();
    // return weekNo + ' ' + `${d.getUTCFullYear()}`.substring(2);
  }

  setRatings() {
    this.showChart = false;
    this.loading = true;

    this.barChartLabels = [];
    this.barChartLabels1 = [];
    this.barChartLabels2 = [];
    this.barChartLabels3 = [];
    this.barChartLabels4 = [];
    this.barChartLabels5 = [];
    let tempBarChartData: any[] = [{}, {}, {}, {}, {}, {}];
    let tempBarChartDataI: any[] = [{}, {}, {}, {}, {}, {}];

    this.stars.forEach((star: any) => {
      if (star.ratings && star.ratings.length > 0) {
        star.value =
          star.ratings.reduce(function(acc: any, val: any) {
            return acc + val;
          }, 0) / star.ratings.length;
      } else {
        star.value = 0;
      }
    });

    this.loading = false;
    this.feedbacks.forEach((feedback: any) => {
      let key = '';
      let d = Date.parse(feedback.postedAt);
      let dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
      let [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);
      let kw = this.getWeekNumber(feedback.postedAt);

      if (this.selectDate === TypeOfDate.MONTHLY) {
        key = moment(feedback.postedAt).format('MM.Y');
      } else if (this.selectDate === TypeOfDate.WEEKLY) {
        key = `${kw}`;
      } else if (this.selectDate === TypeOfDate.DAILY) {
        key = `${da}.${mo}.${ye}`;
      } else if (this.selectDate === TypeOfDate.LASTSEVEN) {
        key = moment(feedback.postedAt).format('dddd');
      }

      if (
        moment(feedback.postedAt).format('YMM') >=
          moment()
            .subtract(this.showMonths, 'months')
            .format('YMM') &&
        this.selectDate === TypeOfDate.MONTHLY
      ) {
        if (feedback.generalRating) {
          if (!this.barChartLabels.includes(key)) {
            this.barChartLabels.push(key);
          }
          if (tempBarChartData[0][key]) {
            tempBarChartData[0][key] = tempBarChartData[0][key] + feedback.generalRating;
            tempBarChartDataI[0][key].count++;
          } else {
            tempBarChartData[0][key] = feedback.generalRating;
            tempBarChartDataI[0][key] = { count: 1 };
          }
        }
        if (feedback.serviceRating) {
          if (!this.barChartLabels1.includes(key)) {
            this.barChartLabels1.push(key);
          }
          if (tempBarChartData[1][key]) {
            tempBarChartData[1][key] = tempBarChartData[1][key] + feedback.serviceRating;
            tempBarChartDataI[1][key].count++;
          } else {
            tempBarChartData[1][key] = feedback.serviceRating;
            tempBarChartDataI[1][key] = { count: 1 };
          }
        }
        if (feedback.sauberkeitRating) {
          if (!this.barChartLabels2.includes(key)) {
            this.barChartLabels2.push(key);
          }
          if (tempBarChartData[2][key]) {
            tempBarChartData[2][key] = tempBarChartData[2][key] + feedback.sauberkeitRating;
            tempBarChartDataI[2][key].count++;
          } else {
            tempBarChartData[2][key] = feedback.sauberkeitRating;
            tempBarChartDataI[2][key] = { count: 1 };
          }
        }
        if (feedback.essenRating) {
          if (!this.barChartLabels3.includes(key)) {
            this.barChartLabels3.push(key);
          }
          if (tempBarChartData[3][key]) {
            tempBarChartData[3][key] = tempBarChartData[3][key] + feedback.essenRating;
            tempBarChartDataI[3][key].count++;
          } else {
            tempBarChartData[3][key] = feedback.essenRating;
            tempBarChartDataI[3][key] = { count: 1 };
          }
        }
        if (feedback.ambienteRating) {
          if (!this.barChartLabels4.includes(key)) {
            this.barChartLabels4.push(key);
          }
          if (tempBarChartData[4][key]) {
            tempBarChartData[4][key] = tempBarChartData[4][key] + feedback.ambienteRating;
            tempBarChartDataI[4][key].count++;
          } else {
            tempBarChartData[4][key] = feedback.ambienteRating;
            tempBarChartDataI[4][key] = { count: 1 };
          }
        }
        if (feedback.preisLeistungRating) {
          if (!this.barChartLabels5.includes(key)) {
            this.barChartLabels5.push(key);
          }
          if (tempBarChartData[5][key]) {
            tempBarChartData[5][key] = tempBarChartData[5][key] + feedback.preisLeistungRating;
            tempBarChartDataI[5][key].count++;
          } else {
            tempBarChartData[5][key] = feedback.preisLeistungRating;
            tempBarChartDataI[5][key] = { count: 1 };
          }
        }
      } else {
        if (feedback.generalRating) {
          if (!this.barChartLabels.includes(key)) {
            this.barChartLabels.push(key);
          }
          if (tempBarChartData[0][key]) {
            tempBarChartData[0][key] = tempBarChartData[0][key] + feedback.generalRating;
            tempBarChartDataI[0][key].count++;
          } else {
            tempBarChartData[0][key] = feedback.generalRating;
            tempBarChartDataI[0][key] = { count: 1 };
          }
        }
        if (feedback.serviceRating) {
          if (!this.barChartLabels1.includes(key)) {
            this.barChartLabels1.push(key);
          }
          if (tempBarChartData[1][key]) {
            tempBarChartData[1][key] = tempBarChartData[1][key] + feedback.serviceRating;
            tempBarChartDataI[1][key].count++;
          } else {
            tempBarChartData[1][key] = feedback.serviceRating;
            tempBarChartDataI[1][key] = { count: 1 };
          }
        }
        if (feedback.sauberkeitRating) {
          if (!this.barChartLabels2.includes(key)) {
            this.barChartLabels2.push(key);
          }
          if (tempBarChartData[2][key]) {
            tempBarChartData[2][key] = tempBarChartData[2][key] + feedback.sauberkeitRating;
            tempBarChartDataI[2][key].count++;
          } else {
            tempBarChartData[2][key] = feedback.sauberkeitRating;
            tempBarChartDataI[2][key] = { count: 1 };
          }
        }
        if (feedback.essenRating) {
          if (!this.barChartLabels3.includes(key)) {
            this.barChartLabels3.push(key);
          }
          if (tempBarChartData[3][key]) {
            tempBarChartData[3][key] = tempBarChartData[3][key] + feedback.essenRating;
            tempBarChartDataI[3][key].count++;
          } else {
            tempBarChartData[3][key] = feedback.essenRating;
            tempBarChartDataI[3][key] = { count: 1 };
          }
        }
        if (feedback.ambienteRating) {
          if (!this.barChartLabels4.includes(key)) {
            this.barChartLabels4.push(key);
          }
          if (tempBarChartData[4][key]) {
            tempBarChartData[4][key] = tempBarChartData[4][key] + feedback.ambienteRating;
            tempBarChartDataI[4][key].count++;
          } else {
            tempBarChartData[4][key] = feedback.ambienteRating;
            tempBarChartDataI[4][key] = { count: 1 };
          }
        }
        if (feedback.preisLeistungRating) {
          if (!this.barChartLabels5.includes(key)) {
            this.barChartLabels5.push(key);
          }
          if (tempBarChartData[5][key]) {
            tempBarChartData[5][key] = tempBarChartData[5][key] + feedback.preisLeistungRating;
            tempBarChartDataI[5][key].count++;
          } else {
            tempBarChartData[5][key] = feedback.preisLeistungRating;
            tempBarChartDataI[5][key] = { count: 1 };
          }
        }
      }
    });

    let tempData: any[] = [];
    let tempDataI: any[] = [];

    tempData[0] = Object.values(tempBarChartData[0]);
    tempDataI[0] = Object.values(tempBarChartDataI[0]);

    tempData[1] = Object.values(tempBarChartData[1]);
    tempDataI[1] = Object.values(tempBarChartDataI[1]);

    tempData[2] = Object.values(tempBarChartData[2]);
    tempDataI[2] = Object.values(tempBarChartDataI[2]);

    tempData[3] = Object.values(tempBarChartData[3]);
    tempDataI[3] = Object.values(tempBarChartDataI[3]);

    tempData[4] = Object.values(tempBarChartData[4]);
    tempDataI[4] = Object.values(tempBarChartDataI[4]);

    tempData[5] = Object.values(tempBarChartData[5]);
    tempDataI[5] = Object.values(tempBarChartDataI[5]);

    // for (let i = 0; i <= 5; i++) {
    //   // console.log(i, tempData[i], tempDataI[i]);
    //   // more statements
    // }

    let i = 0;
    tempData.forEach((row: any) => {
      let j = 0;
      row.forEach((val: any) => {
        tempData[i][j] = (val / tempDataI[i][j].count).toFixed(2);
        j++;
      });
      i++;
    });
    if (this.selectDate === TypeOfDate.LASTSEVEN) {
      const dayOrder: any = { Montag: 1, Dienstag: 2, Mittwoch: 3, Donnerstag: 4, Freitag: 5, Samstag: 6, Sonntag: 7 };
      if (tempData[0]) {
        const dayDatePairs = this.barChartLabels.map((day, index) => ({ day, date: tempData[0][index] }));
        dayDatePairs.sort((a: any, b: any) => dayOrder[a.day] - dayOrder[b.day]);
        tempData[0] = dayDatePairs.map(pair => pair.date);
        this.barChartLabels = dayDatePairs.map(pair => pair.day);
      }
      if (tempData[1]) {
        const dayDatePairs = this.barChartLabels.map((day, index) => ({ day, date: tempData[1][index] }));
        dayDatePairs.sort((a: any, b: any) => dayOrder[a.day] - dayOrder[b.day]);
        tempData[1] = dayDatePairs.map(pair => pair.date);
        this.barChartLabels1 = dayDatePairs.map(pair => pair.day);
      }
      if (tempData[2]) {
        const dayDatePairs = this.barChartLabels.map((day, index) => ({ day, date: tempData[2][index] }));
        dayDatePairs.sort((a: any, b: any) => dayOrder[a.day] - dayOrder[b.day]);
        tempData[2] = dayDatePairs.map(pair => pair.date);
        this.barChartLabels2 = dayDatePairs.map(pair => pair.day);
      }
      if (tempData[3]) {
        const dayDatePairs = this.barChartLabels.map((day, index) => ({ day, date: tempData[3][index] }));
        dayDatePairs.sort((a: any, b: any) => dayOrder[a.day] - dayOrder[b.day]);
        tempData[3] = dayDatePairs.map(pair => pair.date);
        this.barChartLabels3 = dayDatePairs.map(pair => pair.day);
      }
      if (tempData[4]) {
        const dayDatePairs = this.barChartLabels.map((day, index) => ({ day, date: tempData[4][index] }));
        dayDatePairs.sort((a: any, b: any) => dayOrder[a.day] - dayOrder[b.day]);
        tempData[4] = dayDatePairs.map(pair => pair.date);
        this.barChartLabels4 = dayDatePairs.map(pair => pair.day);
      }
      if (tempData[5]) {
        const dayDatePairs = this.barChartLabels.map((day, index) => ({ day, date: tempData[5][index] }));
        dayDatePairs.sort((a: any, b: any) => dayOrder[a.day] - dayOrder[b.day]);
        tempData[5] = dayDatePairs.map(pair => pair.date);
        this.barChartLabels5 = dayDatePairs.map(pair => pair.day);
      }
    }

    this.barChartData = [
      {
        data: tempData[0],
        label: 'Gesamteindruck'
      }
    ];
    this.barChartData1 = [
      {
        data: tempData[1],
        label: 'Service'
      }
    ];

    this.barChartData2 = [
      {
        data: tempData[2],
        label: 'Sauberkeit'
      }
    ];

    this.barChartData3 = [
      {
        data: tempData[3],
        label: 'Essen'
      }
    ];

    this.barChartData4 = [
      {
        data: tempData[4],
        label: 'Ambiente'
      }
    ];

    this.barChartData5 = [
      {
        data: tempData[5],
        label: 'Preis/Leistung'
      }
    ];
    this.showChart = true;
    if (this.stars.length > 0) {
      this.setStarRatingDoughnutChart(this.stars);
    }
  }

  setStarRatingDoughnutChart(starRating: any) {
    this.showChartDoughnut = false;
    if (starRating[0].label) {
      const occurrencesMap = new Map<number, number>([
        [5, 0],
        [4, 0],
        [3, 0],
        [2, 0],
        [1, 0]
      ]);
      starRating[0].ratings.forEach((item: any) => {
        occurrencesMap.set(item, (occurrencesMap.get(item) || 0) + 1);
      });

      this.mapValueOfStars = Array.from(occurrencesMap.entries());

      this.doughnutChartColors = [
        {
          backgroundColor: this.mapValueOfStars.map(value => {
            switch (value[0]) {
              case 1:
                return '#00c3eb';
              case 2:
                return '#008eb3';
              case 3:
                return '#01ae7d';
              case 4:
                return '#fdd11b';
              case 5:
                return '#f2215a'; // Customize this color for 5 stars
              default:
                return ''; // Default color for unknown values
            }
          })
        }
      ];

      this.items = Array.from(occurrencesMap.entries()).map(([value, count]) => {
        const percentage = (count / starRating[0].ratings.length) * 100;
        return { value, count, percentage };
      });
      let dataSets: any = [];
      let dataLabels: any = [];
      this.items.map((val: any) => {
        dataSets.push(parseFloat(val.percentage.toFixed(2)));
        dataLabels.push(val.value);
      });
      this.doughnutChartLabels = dataLabels;
      this.doughnutChartData = [
        {
          data: dataSets,
          label: starRating[0].label
        }
      ];

      this.doughnutChatOption = {
        legend: {
          display: false // Set to false to hide the labels
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              const value = dataset.data[tooltipItem.index];
              return `${label}: ${value}%`;
            }
          }
        }
      };
    }

    if (starRating[1].label) {
      const occurrencesMap1 = new Map<number, number>([
        [5, 0],
        [4, 0],
        [3, 0],
        [2, 0],
        [1, 0]
      ]);
      starRating[1].ratings.forEach((item: any) => {
        occurrencesMap1.set(item, (occurrencesMap1.get(item) || 0) + 1);
      });

      this.mapValueOfStars1 = Array.from(occurrencesMap1.entries());

      this.doughnutChartColors1 = [
        {
          backgroundColor: this.mapValueOfStars1.map(value => {
            switch (value[0]) {
              case 1:
                return '#00c3eb';
              case 2:
                return '#008eb3';
              case 3:
                return '#01ae7d';
              case 4:
                return '#fdd11b';
              case 5:
                return '#f2215a'; // Customize this color for 5 stars
              default:
                return ''; // Default color for unknown values
            }
          })
        }
      ];

      this.items1 = Array.from(occurrencesMap1.entries()).map(([value, count]) => {
        const percentage = (count / starRating[1].ratings.length) * 100;
        return { value, count, percentage };
      });
      let dataSets: any = [];
      let dataLabels: any = [];
      this.items1.map((val: any) => {
        dataSets.push(parseFloat(val.percentage.toFixed(2)));
        dataLabels.push(val.value);
      });
      this.doughnutChartLabels1 = dataLabels;
      this.doughnutChartData1 = [
        {
          data: dataSets,
          label: starRating[1].label
        }
      ];

      this.doughnutChatOption1 = {
        legend: {
          display: false // Set to false to hide the labels
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              const value = dataset.data[tooltipItem.index];
              return `${label}: ${value}%`;
            }
          }
        }
      };
    }

    if (starRating[2].label) {
      const occurrencesMap2 = new Map<number, number>([
        [5, 0],
        [4, 0],
        [3, 0],
        [2, 0],
        [1, 0]
      ]);
      starRating[2].ratings.forEach((item: any) => {
        occurrencesMap2.set(item, (occurrencesMap2.get(item) || 0) + 1);
      });

      this.mapValueOfStars2 = Array.from(occurrencesMap2.entries());

      this.doughnutChartColors2 = [
        {
          backgroundColor: this.mapValueOfStars2.map(value => {
            switch (value[0]) {
              case 1:
                return '#00c3eb';
              case 2:
                return '#008eb3';
              case 3:
                return '#01ae7d';
              case 4:
                return '#fdd11b';
              case 5:
                return '#f2215a'; // Customize this color for 5 stars
              default:
                return ''; // Default color for unknown values
            }
          })
        }
      ];

      this.items2 = Array.from(occurrencesMap2.entries()).map(([value, count]) => {
        const percentage = (count / starRating[2].ratings.length) * 100;
        return { value, count, percentage };
      });
      let dataSets: any = [];
      let dataLabels: any = [];
      this.items2.map((val: any) => {
        dataSets.push(parseFloat(val.percentage.toFixed(2)));
        dataLabels.push(val.value);
      });
      this.doughnutChartLabels2 = dataLabels;
      this.doughnutChartData2 = [
        {
          data: dataSets,
          label: starRating[2].label
        }
      ];

      this.doughnutChatOption2 = {
        legend: {
          display: false // Set to false to hide the labels
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              const value = dataset.data[tooltipItem.index];
              return `${label}: ${value}%`;
            }
          }
        }
      };
    }

    if (starRating[3].label) {
      const occurrencesMap3 = new Map<number, number>([
        [5, 0],
        [4, 0],
        [3, 0],
        [2, 0],
        [1, 0]
      ]);
      starRating[3].ratings.forEach((item: any) => {
        occurrencesMap3.set(item, (occurrencesMap3.get(item) || 0) + 1);
      });

      this.mapValueOfStars3 = Array.from(occurrencesMap3.entries());

      this.doughnutChartColors3 = [
        {
          backgroundColor: this.mapValueOfStars3.map(value => {
            switch (value[0]) {
              case 1:
                return '#00c3eb';
              case 2:
                return '#008eb3';
              case 3:
                return '#01ae7d';
              case 4:
                return '#fdd11b';
              case 5:
                return '#f2215a'; // Customize this color for 5 stars
              default:
                return ''; // Default color for unknown values
            }
          })
        }
      ];

      this.items3 = Array.from(occurrencesMap3.entries()).map(([value, count]) => {
        const percentage = (count / starRating[3].ratings.length) * 100;
        return { value, count, percentage };
      });
      let dataSets: any = [];
      let dataLabels: any = [];
      this.items3.map((val: any) => {
        dataSets.push(parseFloat(val.percentage.toFixed(2)));
        dataLabels.push(val.value);
      });
      this.doughnutChartLabels3 = dataLabels;
      this.doughnutChartData3 = [
        {
          data: dataSets,
          label: starRating[3].label
        }
      ];

      this.doughnutChatOption3 = {
        legend: {
          display: false // Set to false to hide the labels
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              const value = dataset.data[tooltipItem.index];
              return `${label}: ${value}%`;
            }
          }
        }
      };
    }

    if (starRating[4].label) {
      const occurrencesMap4 = new Map<number, number>([
        [5, 0],
        [4, 0],
        [3, 0],
        [2, 0],
        [1, 0]
      ]);
      starRating[4].ratings.forEach((item: any) => {
        occurrencesMap4.set(item, (occurrencesMap4.get(item) || 0) + 1);
      });

      this.mapValueOfStars4 = Array.from(occurrencesMap4.entries());

      this.doughnutChartColors4 = [
        {
          backgroundColor: this.mapValueOfStars4.map(value => {
            switch (value[0]) {
              case 1:
                return '#00c3eb';
              case 2:
                return '#008eb3';
              case 3:
                return '#01ae7d';
              case 4:
                return '#fdd11b';
              case 5:
                return '#f2215a'; // Customize this color for 5 stars
              default:
                return ''; // Default color for unknown values
            }
          })
        }
      ];

      this.items4 = Array.from(occurrencesMap4.entries()).map(([value, count]) => {
        const percentage = (count / starRating[4].ratings.length) * 100;
        return { value, count, percentage };
      });
      let dataSets: any = [];
      let dataLabels: any = [];
      this.items4.map((val: any) => {
        dataSets.push(parseFloat(val.percentage.toFixed(2)));
        dataLabels.push(val.value);
      });
      this.doughnutChartLabels4 = dataLabels;
      this.doughnutChartData4 = [
        {
          data: dataSets,
          label: starRating[4].label
        }
      ];

      this.doughnutChatOption4 = {
        legend: {
          display: false // Set to false to hide the labels
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              const value = dataset.data[tooltipItem.index];
              return `${label}: ${value}%`;
            }
          }
        }
      };
    }

    if (starRating[5].label) {
      const occurrencesMap5 = new Map<number, number>([
        [5, 0],
        [4, 0],
        [3, 0],
        [2, 0],
        [1, 0]
      ]);
      starRating[5].ratings.forEach((item: any) => {
        occurrencesMap5.set(item, (occurrencesMap5.get(item) || 0) + 1);
      });

      this.mapValueOfStars5 = Array.from(occurrencesMap5.entries());

      this.doughnutChartColors5 = [
        {
          backgroundColor: this.mapValueOfStars5.map(value => {
            switch (value[0]) {
              case 1:
                return '#00c3eb';
              case 2:
                return '#008eb3';
              case 3:
                return '#01ae7d';
              case 4:
                return '#fdd11b';
              case 5:
                return '#f2215a'; // Customize this color for 5 stars
              default:
                return ''; // Default color for unknown values
            }
          })
        }
      ];

      this.items5 = Array.from(occurrencesMap5.entries()).map(([value, count]) => {
        const percentage = (count / starRating[5].ratings.length) * 100;
        return { value, count, percentage };
      });
      let dataSets: any = [];
      let dataLabels: any = [];
      this.items5.map((val: any) => {
        dataSets.push(parseFloat(val.percentage.toFixed(2)));
        dataLabels.push(val.value);
      });
      this.doughnutChartLabels5 = dataLabels;
      this.doughnutChartData5 = [
        {
          data: dataSets,
          label: starRating[5].label
        }
      ];

      this.doughnutChatOption5 = {
        legend: {
          display: false // Set to false to hide the labels
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              const value = dataset.data[tooltipItem.index];
              return `${label}: ${value}%`;
            }
          }
        }
      };
    }
  }

  // Function to validate To and From date

  validateDateRange() {
    if (moment(this.dates.from).isAfter(this.dates.to)) {
      this.showErrorMessage = true;
    } else {
      this.showErrorMessage = false;
    }
  }

  // Function to print data in a table based on feedback and service ratings.
  getDailyDetailsInTableOld() {
    const dailyAveragesMap = new Map<string, { total: any; count: number }>();
    let dateCounts: { date: string; count: number }[] = [];
    const countsMap = new Map<string, number>();

    // Calculate the average of services per day
    this.feedbacks.forEach(feedback => {
      const date = feedback.postedAt.split(' ')[0];
      if (!dailyAveragesMap.has(date)) {
        dailyAveragesMap.set(date, { total: feedback, count: 1 });
      } else {
        const current = dailyAveragesMap.get(date);
        current.count++;
        current.total.generalRating += feedback.generalRating;
        current.total.serviceRating += feedback.serviceRating;
        current.total.sauberkeitRating += feedback.sauberkeitRating;
        current.total.essenRating += feedback.essenRating;
        current.total.ambienteRating += feedback.ambienteRating;
        current.total.preisLeistungRating += feedback.preisLeistungRating;
      }
    });

    this.dailyAverages = [];
    // Calculate average of total ratings per service in a day
    dailyAveragesMap.forEach((value, date) => {
      const averages = {
        generalRating: (value.total.generalRating / value.count).toFixed(2),
        serviceRating: (value.total.serviceRating / value.count).toFixed(2),
        sauberkeitRating: (value.total.sauberkeitRating / value.count).toFixed(2),
        essenRating: (value.total.essenRating / value.count).toFixed(2),
        ambienteRating: (value.total.ambienteRating / value.count).toFixed(2),
        preisLeistungRating: (value.total.preisLeistungRating / value.count).toFixed(2),
        postedAt: date,
        totalRating: (
          (value.total.generalRating +
            value.total.serviceRating +
            value.total.sauberkeitRating +
            value.total.essenRating +
            value.total.ambienteRating +
            value.total.preisLeistungRating) /
          6
        ).toFixed(2)
      };
      this.dailyAverages.push({ date, averages });
    });

    this.feedbacks.forEach(item => {
      const date = item.postedAt.split(' ')[0]; // Extracting date from the timestamp
      if (countsMap.has(date)) {
        countsMap.set(date, countsMap.get(date)! + 1); // Increment count if date exists
      } else {
        countsMap.set(date, 1); // Initialize count if date doesn't exist
      }
    });

    // Convert map to array of objects
    dateCounts = Array.from(countsMap).map(([date, count]) => ({ date, count }));
    let mergeArray = this.dailyAverages.map((item, index) => {
      return {
        ...item.averages,
        ...dateCounts[index]
      };
    });
    this.dailyAverages = mergeArray;
  }

  calculateAverage = (arr: any) => {
    if (arr.length === 0) return '0.00';
    const sum = arr.reduce((acc: any, val: any) => acc + val, 0);
    return (sum / arr.length).toFixed(2);
  };

  getDailyDetailsInTable() {
    const filteredData = this.feedbacks.reduce((acc, feedback) => {
      const date = feedback.postedAt ? feedback.postedAt.split(' ')[0] : null;
      if (date) {
        if (!acc[date]) {
          acc[date] = {
            ambienteRating: [],
            essenRating: [],
            generalRating: [],
            preisLeistungRating: [],
            sauberkeitRating: [],
            serviceRating: []
          };
        }

        if (feedback.ambienteRating !== undefined) acc[date].ambienteRating.push(feedback.ambienteRating);
        if (feedback.essenRating !== undefined) acc[date].essenRating.push(feedback.essenRating);
        if (feedback.generalRating !== undefined) acc[date].generalRating.push(feedback.generalRating);
        if (feedback.preisLeistungRating !== undefined)
          acc[date].preisLeistungRating.push(feedback.preisLeistungRating);
        if (feedback.sauberkeitRating !== undefined) acc[date].sauberkeitRating.push(feedback.sauberkeitRating);
        if (feedback.serviceRating !== undefined) acc[date].serviceRating.push(feedback.serviceRating);
      }
      return acc;
    }, {});

    this.dailyAverages = Object.keys(filteredData).map(date => {
      const data = filteredData[date];
      return {
        postedAt: date,
        ambienteRating: this.calculateAverage(data.ambienteRating),
        essenRating: this.calculateAverage(data.essenRating),
        generalRating: this.calculateAverage(data.generalRating),
        preisLeistungRating: this.calculateAverage(data.preisLeistungRating),
        sauberkeitRating: this.calculateAverage(data.sauberkeitRating),
        serviceRating: this.calculateAverage(data.serviceRating),
        count: data.generalRating.length,
        totalRating: (
          (parseFloat(this.calculateAverage(data.ambienteRating)) +
            parseFloat(this.calculateAverage(data.essenRating)) +
            parseFloat(this.calculateAverage(data.generalRating)) +
            parseFloat(this.calculateAverage(data.preisLeistungRating)) +
            parseFloat(this.calculateAverage(data.sauberkeitRating)) +
            parseFloat(this.calculateAverage(data.serviceRating))) /
          6
        ).toFixed(2)
      };
    });
    return this.dailyAverages;
  }

  getDailyGeneralReviewsInTable() {
    const filteredData = this.feedbacks.reduce((acc, feedback) => {
      const date = feedback.postedAt ? feedback.postedAt.split(' ')[0] : null;
      if (date) {
        if (!acc[date]) {
          acc[date] = {
            generalRating: [],
            rating1: 0,
            rating2: 0,
            rating3: 0,
            rating4: 0,
            rating5: 0
          };
        }
        if (feedback.generalRating !== undefined) {
          acc[date].generalRating.push(feedback.generalRating);
          if (feedback.generalRating === 1) acc[date].rating1++;
          if (feedback.generalRating === 2) acc[date].rating2++;
          if (feedback.generalRating === 3) acc[date].rating3++;
          if (feedback.generalRating === 4) acc[date].rating4++;
          if (feedback.generalRating === 5) acc[date].rating5++;
        }
      }
      return acc;
    }, {});

    this.dailyAveragesGoogle = Object.keys(filteredData).map(date => {
      const data = filteredData[date];
      return {
        postedAt: date,
        generalRating: this.calculateAverage(data.generalRating),
        count: data.generalRating.length,
        rating1: data.rating1,
        rating2: data.rating2,
        rating3: data.rating3,
        rating4: data.rating4,
        rating5: data.rating5
      };
    });
    return this.dailyAveragesGoogle;
  }

  loginWithGoogle() {
    const googleAccountClientId = Constants.googleAccountClientId;
    // const redirectUri = `${environment.baseUrl}client/${this.authService.activeClientId}/feedback/statistic`;
    const redirectUri = `${environment.baseUrlHetzner}`;
    const scope = Constants.scopeReviewAPI; // Minimal scope for basic authentication
    // Redirect user to Google OAuth consent screen
    const state = this.authService.activeClientId; // Custom state parameter
    window.location.href =
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${encodeURIComponent(googleAccountClientId)}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `response_type=token&` +
      `scope=${encodeURIComponent(scope)}&` +
      `state=${encodeURIComponent(state)}`;
  }

  // Fetch all business locations
  getAllBusinessLocations() {
    if (this.showReviewData.googleReviews) {
      this.showDetailedReviews = false;
    }

    const accessToken = localStorage.getItem('googleAccessToken');
    if (accessToken && this.tokenValid && this.showReviewData.googleReviews) {
      this.clientService
        .fetchAllBusinessLocations(accessToken)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          res => {
            this.googleBusinessLocations = res;
            // localStorage.setItem('googleBusinessLocations', JSON.stringify(res));
            // this.googleBusinessLocations = localStorage.getItem('googleBusinessLocations')
            //   ? JSON.parse(localStorage.getItem('googleBusinessLocations'))
            //   : [];
          },
          (err: any) => {
            this.snackBar.open(err && err.error ? err.error.msg : 'Internal error', '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
        );
    }
  }

  checkTokenValidityAndSetTimer(): void {
    const expiryTime = localStorage.getItem('googleAccessTokenExpiry');
    if (expiryTime) {
      const expiryTimestamp = parseInt(expiryTime, 10);
      const currentTime = Date.now();
      const timeUntilExpiry = expiryTimestamp - currentTime;

      if (timeUntilExpiry > 0) {
        this.tokenValid = true;
        // Set a timeout to mark the token as invalid when it expires
        this.tokenExpiryTimeout = setTimeout(() => {
          this.tokenValid = false;
        }, timeUntilExpiry);
      } else {
        this.tokenValid = false;
      }
    } else {
      this.tokenValid = false;
    }
  }

  ngOnDestroy(): void {
    // Clear the timeout when the component is destroyed
    if (this.tokenExpiryTimeout) {
      clearTimeout(this.tokenExpiryTimeout);
    }
  }
}
